<template>
    <div>
    <Toolbar class="mb-1">
        
            <template v-slot:start>
               
                <Button
                  
                  icon="pi pi-plus"
                  
                  @click="openNew"
                />
            </template>
            <template v-slot:end>
               
            </template>
        </Toolbar>
    <div class="" style="height:600px;overflow:scroll">
        <!-- {{userlist}} -->
		<div class="col-12 lg:col-6 xl:col-12"  v-for="(data,key) in table_data" :key="key" >
			<!-- <div  > -->
                <br>
			<div class="card mb-0" style="color:white;background-color:#274461;font-size:12px;line-height:0.9" >
				
				<div class="grid">
					<div class="col-2 md:col-2">
						
					</div>
					<div class="grid col-4 md:col-10">
                        
						<div class="col-6 md:col-6">
							<span>Route Name : </span><span style="text-align:right"><b>{{data.result.name?data.result.name:''}}</b></span>
						</div>
                        <div class="col-6 md:col-6">
							<span>State : </span><span style="text-align:right"><b>{{data.result.state?data.result.state:''}}</b></span>
						</div>
                        <div class="col-6 md:col-6">
							<span>City : </span><span style="text-align:right"><b>{{data.result.city?data.result.city:''}}</b></span>
						</div>
                        <div class="col-6 md:col-6">
							<span>Pincode : </span><span style="text-align:right"><b>{{data.result.pincode?data.result.pincode:''}}</b></span>
						</div>
                        <div class="col-6 md:col-6">
							<span>Start Time : </span><span style="text-align:right"><b>{{data.result.start_time?data.result.start_time:''}}</b></span>
						</div><div class="col-6 md:col-6">
							<span>End Time : </span><span style="text-align:right"><b>{{data.result.end_time?data.result.end_time:''}}</b></span>
						</div>
                        
					</div>
						
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="">since last visit</span> -->
			</div>
			<!-- </div> -->
		
		</div>
		
		<div class="col-12" v-if="list==''" style="text-align:left">
            <br>	
			Data Not Found
		</div>
    </div>
    <Dialog header="Add Route" v-model:visible="productDialog" :breakpoints="{'960px': '75vw'}" :style="{width: '60vw'}" :modal="true">
	    
        <div class="col-12">
              <div class="card">
                <div class="p-fluid formgrid grid">
                    
                    <div class="field col-12 md:col-12">
                    <label for="startpoint">Group</label>
                <MultiSelect 
                display="chip" 
                v-model="selectedgroup" 
                :options="groups" 
                optionLabel="name" 
                placeholder="Select Group"
                :maxSelectedLabels="10" 
                 />
           
            
      
                    
                  </div>
                </div>
                  </div>
                  </div>
                
        
        <template #footer>
		<Button label="ADD" @click="submit_details" icon="pi pi-check" class="p-button-warning"/>
		</template>
	</Dialog>


    

          
</div>
</template>

<script>
import apis from "@/apis";
import ProductService from "../../service/ProductService";
import { FilterMatchMode } from "primevue/api";
import readXlsxFile from 'read-excel-file'
import { stringify } from "qs";


export default {
  data() {
    return {
      
        delete_data:{},
        delete_data_index:'',
      file:[],
      limit: 10,
      filters: {},
      offset: 1,
      page_no: 1,
      list:[],
      stop_name:"",
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,

      productDialog: false,
      table_data: [],

      groups:[],
      selectedgroup:null,
      
      name: "",
      status: "",
      location: "",
      mobile_number: "",
      parent_mobile_number:"",

      products: [],
      userlist:[],

      dropdownItems: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" }
      ],
      selectstatus: { name: "Active", value: "Active" },

      productService: null
    };
  },
  props: ["departmentdata","groupdata"],
  methods: {
   
    
    isNumberKey(evt) {
            evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }

  },
    fileuploaduser(event)
    {
      // alert("here");
      console.log(event);
      const input = this.$refs.fileupload1;
      // console.log(input);
      if(input.files[0].name.slice((Math.max(0, input.files[0].name.lastIndexOf(".")) || Infinity) + 1)=='xlsx')
      {
        readXlsxFile(input.files[0]).then((rows)=>{
          for(var i=1;i<rows.length;i++)
          {
            this.products.push({
        sr_no:this.products.length+1,
        name: rows[i][0],
        mobile_number: rows[i][1],
        status: "Active",
        location: "",
        _id:'0'    
        });
          }
      })
      }
      else
      {
        alert("Please select valid file");
      }
    },
    onUpload() {
				// alert(123);
				this.$toast.add({severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000});
			},
    
    checkName(str)
    {
      if(/^[a-zA-Z ]*$/.test(str) == false)
      {
        return str;
      }
      else
      {
        return "";
      }
    },
    checkMobile(str)
    {
      if(/^[0-9]*$/.test(str) == false || str.length !=10)
      {
        return str;
      }
      else
      {
        return "";
      }
    },
    submit_details()
    { 
        if (!this.selectedgroup || this.selectedgroup.length === 0) {
            this.$toast.add({
                severity: "error",
                summary: "error",
                detail: "Please select at least one group",
                life: 3000,
            });
            return false;
            }

        const temp = this.selectedgroup.map(group => group.value);


      var data = {
          
            "department_id":this.departmentdata._id,
            "client_id": this.departmentdata.client_id,
            "group_ids": temp
          };

          console.log(data);
          this.isLoadingModel = true;
          var promise = apis.clientDepartmentMappGroup(data);
          promise
            .then((responseapi) => {
              this.$swal({title:responseapi.data.message , confirmButtonText: this.$t('ok')});
              this.isLoadingModel = false;
              this.productDialog = false;
              
            })
            .catch((error) => {
              //console.log(error);
              this.$swal.fire(error.response.data);
              this.isLoadingModel = false;
              this.productDialog = false;
             
  
              
              
            });



    },
    group_list()
    {
      
            this.loading = true;
            var data = { client_id: this.departmentdata.client_id };
            var promise = apis.groupList(data);
            promise.then((response) => {
                this.loading = false;
                console.log(response);
                this.list = response.data.data;
                if (response.data.data) {
                this.groups = response.data.data.map(group => ({ name: group.name, value: group._id }));
                }
                //-----------------------------------------------------------------------------
                this.selectedgroup = null;
                    if (this.departmentdata.group_ids) {
                    this.selectedgroup = this.groups
                        .filter(group => this.departmentdata.group_ids.includes(group.value))
                        .map(group => ({ name: group.name, value: group.value }));
                    }

            });
       
    },
    department_group_list()
    {
      
            this.loading = true;
            var data = { _id: this.departmentdata._id };
            var promise = apis.department_grouplist(data);
            promise.then((response) => {
                this.loading = false;
                console.log(response);
                this.table_data = response.data.data;
                
               

            });
       
    },
    
      
    reset_details() {
      this.selectstatus = { name: "Active", value: "Active" };
      this.status = "";
      this.name = "";
      this.mobile_number = "";
    },
    change_status() {
      this.status = this.selectstatus.value;
    },
    async adduser() {
      
    this.change_status();
      if (!this.name) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter  Name",
          life: 3000
        });
        return false;
      }
      if (!this.mobile_number) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter  Mobile number",
          life: 3000
        });
        return false;
      }
      if (this.mobile_number.length!=10) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter valid Mobile number",
          life: 3000
        });
        return false;
      }
      if (this.group_type=='School' && this.parent_mobile_number.length!=10) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter valid Parent Mobile number",
          life: 3000
        });
        return false;
      }
      if (!this.status) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Select Status",
          life: 3000
        });
        return false;
      }
      if (
        this.location == undefined ||
        this.location == null ||
        this.location == []
      ) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Location",
          life: 3000
        });
        return false;
      }
      var data = {
        sr_no:this.products.length+1,
        name: this.name,
        mobile_number: this.mobile_number,
        parent_mobile_number:this.parent_mobile_number,
        Class:this.Class,
        standard:this.standard,
        roll_no:this.roll_no,
        status: this.status,
        location: this.location,
        _id:'0'
      };
      // this.pick_dropdown_array.push(this.name+this.mobile_number);
      this.pick_search_array[this.name+this.mobile_number]=this.pick_search;
      
      this.products.push(data);
      await this.reset_details();
    },
    
    
    openNew() {

      this.reset_details();
      this.product = {};
      this.products = [];
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    D_M_Y_formatDate(date_parm) {
      if (date_parm) {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd + "-" + mm + "-" + yyyy;
        // alert(dd)
        if (dd.toString() != "NaN") {
          //this.receipt_new_date = new_date;
          return new_date;
        } else {
          return date_parm;
        }
      }
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      };
    }
  },
  watch:
  {
    list:
    {
           
      handler(newValue, oldValue) {
              this.userlist=this.list;
        
      }

    }
  },
  mounted() {
    // alert('here');
    // this.userlist=this.list;

    this.group_list();
    this.department_group_list();
  },
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    //
  }
};
</script>